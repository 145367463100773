import './scss/main.scss';

import {dataFilterOnClick} from "./js/app.js";
import {gridItemShave} from "./js/app.js";
import './js/custom-bootstrap.js';
import 'jquery-colorbox';
import 'jquery-lazy';
import {equalHeight} from "./js/app.js";

import infiniteScroll from 'infinite-scroll';

import {loadGridItemsAndModals} from "./js/app.js";

//https://infinite-scroll.com/options#oninit
// let infScroll = new infiniteScroll( '.grid', {
//   // defaults listed
//   path: function() {
//   	if ( this.loadCount < 4 ) return '/' + ( this.loadCount + 1 ) + '.html'  	
//   	//return '/page/' + ( this.loadCount + 1 ) + '.html';
//   },
//   // path: undefined,

//   // REQUIRED. Determines the URL for the next page
//   // Set to selector string to use the href of the next page's link
//   // path: '.pagination__next'
//   // Or set with {{#}} in place of the page number in the url
//   // path: '/blog/page/{{#}}'
//   // or set with function
//   // path: function() {
//   //   return return '/articles/P' + ( ( this.loadCount + 1 ) * 10 );
//   // }

//      append: '.grid-item',
//   // append: 'div.modx',
//   // ten element zlicza jako dodane rzeczy z strony
//   // REQUIRED for appending content
//   // Appends selected elements from loaded page to the container

//   checkLastPage: true,
//   // Checks if page has path selector element
//   // Set to string if path is not set as selector string:
//   //   checkLastPage: '.pagination__next'

//   prefill: true,
//   // Loads and appends pages on intialization until scroll requirement is met.

//   responseBody: 'text',
//   // Sets the method used on the response.
//   // Set to 'json' to load JSON.

//   domParseResponse: true,
//   // enables parsing response body into a DOM
//   // disable to load flat text

//   fetchOptions: undefined,
//   // sets custom settings for the fetch() request
//   // for setting headers, cors, or POST method
//   // can be set to an object, or a function that returns an object

//   outlayer: false,
//   // Integrates Masonry, Isotope or Packery
//   // Appended items will be added to the layout

//   scrollThreshold: 0,
//   // Sets the distance between the viewport to scroll area
//   // for scrollThreshold event to be triggered.

//   // trigger scrollThreshold event when viewport is <100px from bottom of scroll area
//   //Disable loading on scroll and the scrollThreshold event with scrollThreshold: false. This is useful if loading with button.

//   elementScroll: false,
//   // Sets scroller to an element for overflow element scrolling

//   loadOnScroll: true,
//   // Loads next page when scroll crosses over scrollThreshold

//   history: false,
//   // history: 'replace',
//   // Changes the browser history and URL.
//   // Set to 'push' to use history.pushState()
//   //    to create new history entries for each page change.

//   historyTitle: true,
//   // Updates the window title. Requires history enabled.

//   hideNav: undefined,
//   // Hides navigation element

//   status: '.loader-ellips',
//   // status: '.page-load-status',
//   // status: undefined,
//   // Displays status elements indicating state of page loading:
//   // .infinite-scroll-request, .infinite-scroll-load, .infinite-scroll-error
//   // status: '.page-load-status'

//   button: undefined,
//   // Enables a button to load pages on click
//   // button: '.load-next-button'

//   onInit: function() {
//     this.on( 'append', function() {
//     	$('div.grid .clearfix').remove();
//     	$('div.grid').append('<div class="clearfix"></div>');
//     })
//   },
//   // onInit: undefined,
//   // called on initialization
//   // useful for binding events on init
//   // onInit: function() {
//   //   this.on( 'append', function() {...})
//   // }

//   debug: true,
//   // Logs events and state changes to the console.
// });

// infScroll.on( 'last', function( body, path ) {
// 	$('.page-load-status').hide();
// 	// console.log(`Last page hit on ${path}`);
// });

// infScroll.on( 'append', function( event, body, path, items, response ) {
// 	//console.log(`Appended ${items.length} items on ${path}`); nie działa ale samo append działą więc zostawiam
// });

// $(window).on('load', function() {
//   const numberOfFiles = 7; // Replace with the desired number of files to load
//   loadGridItemsAndModals(numberOfFiles);
// });



$(".lazy").Lazy();

$(document).ready(function () {
	$(".colorbox").colorbox({
  	rel:'colorbox',
    scalePhotos: true,
    transition:"elastic",
    maxWidth: "90%",
    fixed: false,
    returnFocus: true
  });
});

let windowWidth = $(window).width();

$('.grid-item:not(.no-hover-mobile) .content .onclick').on( "click", function() {
	$(this).closest('.grid-item').toggleClass('hover');
});

dataFilterOnClick();

$(function() {
	$(".img-flex").addClass('start-active');
});

$(window).on('load', function() {
	equalHeight($(".grid-item:not(.hover) .content"));
});

gridItemShave();

$(window).on('resize', function(e) {
  	if ($(window).width() != windowWidth) {
	  	// Update the window width for next time
		windowWidth = $(window).width();

		if (window.RT) clearTimeout(window.RT);
		
	  	window.RT = setTimeout(function() {
			gridItemShave();

	  		equalHeight($(".grid-item:not(.hover) .content"));
		}, 100);
  	}
});

// $(function($) {	 });
// $(document).ready(function () {
// 	const numberOfFiles = 6;
// 	loadGridItemsAndModals(numberOfFiles);
// });

$(window).on('load', function() { $(".page-loader").fadeOut(200); });