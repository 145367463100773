import shave from 'shave';

export function equalHeight(ehSelector) {
	var tallest = 0;
    ehSelector.each( function() {
		$(this).css('height', 'auto');
		var thisHeight = $(this).height();
		if (thisHeight > tallest) {
	    	tallest = thisHeight;
		}
	});
	ehSelector.height(tallest);
}

export function loadGridItemsAndModals(numberOfFiles) {
    const fileNames = Array.from({ length: numberOfFiles }, (_, i) => (i + 1).toString());

    fileNames.forEach(function(fileName) {
        $.ajax({
            url: fileName + '.html',
            success: function(response) {
                const $responseHtml = $(response);
                const $gridItem = $responseHtml.find('.grid-item');
                const $modal = $responseHtml.find('.modal');

                // Append the grid item to the container
                $('#grid-container').append($gridItem);
				console.log($gridItem);

                // Append the modal to the body
                $('body').append($modal);

				// Call the gridItemShave function to apply shave.js to the grid items
                gridItemShave();
            },
            error: function(xhr, status, error) {
                console.error('Error loading file ' + fileName + '.html: ' + error);
            }
        });
    });
}

export function dataFilterOnClick() {
	$(".img-flex").on("click", function () {
		if ($(this).hasClass("reset")) $(".img-flex").removeClass("active").addClass("start-active");		
        else {
            $(".img-flex").removeClass('active').removeClass('start-active');
            $(this).addClass('active');
        }
        var filterClass = $(this).data('filter');
        $(".grid-item").each(function() {
            $(this).hide(); // Hide all items initially
            if ( filterClass === 'reset' ) $(".grid-item").show()
            	//DO TESTOWANIA ININITE SCROLL
            	// else if (filterClass === 'modx') {
                //     if ($(this).hasClass('modx')) {
                //         $(this).show(); // Show grid items with the "modx" system
                //     } else {
                //         $(this).remove(); // Hide grid items without the "modx" system
                //     }
                // }
            // Special handling for 'ps' filter
            else if ( filterClass === 'ps' && ($(this).hasClass('p17') || $(this).hasClass('p16')) ) {
                $(this).show();
            } else if($(this).hasClass(filterClass)) {
                // General case for other filters
                $(this).show();
            }
        });

        $('.lazy').Lazy(); // Re-trigger jQuery Lazy to prevent problems with dynamically shown or hidden elements
		gridItemShave();
    });
};



export function gridItemShave() {
    $('.grid-item').each( function() {
		let $gridItem = $(this);
		let h2Height = $gridItem.find('h2').outerHeight();
		let iconsActionHeight = $gridItem.find('.icons-action').outerHeight();
		let gridItemHeight = $gridItem.outerHeight();

			if ($gridItem.find('.modal-btn').length) {
				// Clone the modal button
				let $modalBtnClone = $gridItem.find('.modal-btn').clone();
				// Apply styles to ensure the clone is not visible but still takes up space
				$modalBtnClone.css({
					visibility: 'hidden',
					position: 'absolute',
					left: '-9999px'
				});
				// Append the clone to the body to measure its height
				$('body').append($modalBtnClone);
				// Measure the height
				let modalBtnHeight = $modalBtnClone.outerHeight();
				// Remove the clone from the DOM
				$modalBtnClone.remove();

				let shaveHeight = gridItemHeight - h2Height - iconsActionHeight - modalBtnHeight - 70;

				let $originalDesc = $(this);

				let $clonedDesc = $originalDesc.clone().css({
					position: 'absolute',
					visibility: 'hidden',
					left: '-9999px'
				}).appendTo('body');
				
				$clonedDesc.find('.desc').addClass("display-block");

				let modalId = $originalDesc.find('.modal-btn').data('bs-target');
				let shaved = $(modalId+' .modal-body .shaved');
				if (shaved.length > 0) {
					let shavedText = shaved.text().replace('[...]', '');
					$clonedDesc.find('.desc p').append(shavedText);
					shaved.remove();
				}

				shave($clonedDesc.find('.desc p'), shaveHeight, { character: '' });				
				
				if ($clonedDesc.find('.js-shave').length > 0) {
					let shavedText1 = $clonedDesc.find('.js-shave').text().replace('[...]', '');
					$(modalId+' .modal-body').prepend('<span class="shaved">'+shavedText1+'</br></br></span>');
				}

					if ($clonedDesc.find('.js-shave').length > 0) {
						$gridItem.addClass('truncated');
						if ($clonedDesc.find('.shaved-char').length <= 0) {
							$clonedDesc.find('.desc .shaved-char-container').append('<span class="shaved-char">[...]</span>');							
						}
						if ($(modalId+' .modal-body .shaved').find('.shaved-char').length <= 0) {
							$(modalId+' .modal-body .shaved').prepend('<span class="shaved-char">[...]</span>');
						}
					}
					else {
						$gridItem.removeClass('truncated');
						$clonedDesc.find('.desc .shaved-char-container .shaved-char').remove();

						$(modalId+' .modal-body .shaved .shaved-char').remove();
					}

				$clonedDesc.find('.js-shave').remove();
				$clonedDesc.find('.js-shave-char').remove();

				$originalDesc.find('.desc p').replaceWith($clonedDesc.find('.desc p'));
				$originalDesc.find('.desc .shaved-char-container').replaceWith($clonedDesc.find('.desc .shaved-char-container'));

				$clonedDesc.remove();
			}
			else {
				let shaveHeight = gridItemHeight - h2Height - iconsActionHeight - 70;

				let $originalDesc = $(this);

				let $clonedDesc = $originalDesc.clone().css(
				{
					position: 'absolute',
					visibility: 'hidden',
					left: '-9999px'
				}).appendTo('body');

				$clonedDesc.find('.desc').addClass("display-block");

				shave($clonedDesc.find('p'), shaveHeight, { character: '[...]' });
				
				// Add tooltip to the shaved description
				$clonedDesc.find('span.js-shave-char').attr('data-bs-toggle', 'tooltip');
				$clonedDesc.find('span.js-shave-char').attr('data-bs-title', $clonedDesc.find('span.js-shave').text());
				$clonedDesc.find('span.js-shave-char').attr('data-bs-placement', 'top');

				$('span.js-shave-char[data-bs-toggle="tooltip"]').tooltip();

				$clonedDesc.find('.desc').removeClass("display-block");

				$originalDesc.find('.desc p').replaceWith($clonedDesc.find('.desc p'));

				$clonedDesc.remove();
			}
	});
};
    